<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-crown_empty-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="
            require(`@/assets/img/shop/offers/meinekrone-offer-overlay-bailoutcrowns-hl-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div
        v-if="rewards[0]"
        style="right: 90px; top: 140px"
        class="position-absolute w-50"
      >
        <div
          class="d-inline-flex w-100 align-items-center justify-content-end"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text bold" style="font-size: 88px">
            {{ formatNumber(rewards[0].crown) }}
          </div>
          <img
            :src="$whitelabel.currencies.secondary.large"
            style="max-width: 100px"
            class="ms-3"
          />
        </div>

        <div
          class="d-inline-flex w-100 align-items-center justify-content-end"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 76px">
            {{ formatNumber(rewards[0].gold) }}
          </div>
          <img
            :src="$whitelabel.currencies.primary.large"
            style="max-width: 100px"
            class="ms-3"
          />
        </div>

        <div class="d-flex align-items-center justify-content-around w-100">
          <div v-if="rewards[0].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[0].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[0].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
      </div>

      <div
        class="submit-button to-center-abs"
        @click="buyItem(0, 'Offer', 'CrownEmpty')"
      >
        <div>
          {{ $t("shop.offers.buy_button", { 0: "" }) }}
          <br />
          <span class="big">{{ formatPrice(price) }} €</span>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferCrownEmpty1",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: false };
  },

  mounted: function () {
    this.$bvModal.show("modal-offer-crown_empty-1");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-crown_empty-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background-size: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-image: url("@/assets/img/shop/offers/bailout-secondary-1.png");
    background-color: transparent;
  }

  .close-button {
    top: 40px;
    right: 10px;
  }

  .headline {
    position: absolute;
    top: -10px;
    left: 30px;
  }

  .submit-button {
    width: 405px;
    height: 182px;
    background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    text-align: center;
    padding-left: 85px;
    padding-right: 70px;
    display: inline-flex;
    line-height: 1;
    bottom: 0px;
    right: 0px;
    margin-right: 0;

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }
}
</style>
