<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-levelup-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container to-center-abs">
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 65px">
            {{ formatNumber(rewards[0].gold) }} x
          </div>
          <BaseAsset
            asset="primary"
            size="big"
            class="ms-2 yellow-text"
            style="width: 66px; height: 66px"
          />
        </div>

        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 65px">
            {{ formatNumber(rewards[0].crown) }} x
          </div>
          <img
            :src="$whitelabel.currencies.secondary.large"
            style="width: 66px"
            class="ms-2 yellow-text"
          />
        </div>

        <div
          class="w-50 mx-auto d-flex align-items-center justify-content-around"
        >
          <div v-if="rewards[0].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[0].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[0].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-center">
          <div class="submit-button" @click="buyItem(0, 'Offer', 'LevelUp')">
            <div>
              {{ $t("shop.offers.buy_button", { 0: "" }) }}
              <br />
              <span class="big">{{ formatPrice(price) }} €</span>
            </div>
          </div>
        </div>
      </div>

      <div class="badge-big">{{ $t("shop.offers.badge3") }}</div>

      <div class="badge-small">
        <div>
          <span class="big">1 X</span> {{ $t("shop.offers.badge2", { 0: "" }) }}
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferLevelup1",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: false };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-levelup-1");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-levelup-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    background-image: url("~@/assets/img/shop/offers/levelup-1.png");
    background-color: transparent;
  }

  .close-button {
    top: 50px;
    right: 30px;
  }

  .offer-reward-container {
    top: 330px;
    line-height: 1.2;
    text-align: left;
    width: 700px;
  }

  .submit-button {
    width: 405px;
    height: 182px;
    background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    line-height: 1;

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }

  .badge-big {
    width: 181px;
    height: 180px;
    background: url(~@/assets/img/shop/offers/badge-big-back.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: $primary-accent;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px $btn-drop-shadow);
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 0;
    line-height: 1.2;
    position: absolute;
    right: 60px;
    bottom: 155px;

    .big {
      color: $primary-accent;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }

  .badge-small {
    width: 143px;
    height: 143px;
    background: url(~@/assets/img/shop/offers/badge-big-back.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $primary-accent;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px $btn-drop-shadow);
    padding-left: 35px;
    padding-right: 35px;
    line-height: 1.3;
    position: absolute;
    right: 50px;
    bottom: 60px;
    padding-bottom: 10px;

    .big {
      color: $primary-accent;
      font-family: Ubuntu-Bold;
      font-size: 38px;
    }
  }
}
</style>
