<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-daily-3"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="
            require(`@/assets/img/shop/offers/offer-overlay-dealdestages-hl-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container1">
        <BaseAsset asset="primary" size="big" />
        <div
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div
            class="bold yellow-text"
            style="font-size: 32px"
            :class="rewards[0].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[0].gold) }}
          </div>
        </div>

        <div
          class="d-inline-flex align-items-center"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="amount-crown yellow-text bold" style="font-size: 32px">
            {{ formatNumber(rewards[0].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-1 yellow-text" />
        </div>

        <div
          class="w-75 d-inline-flex align-items-center justify-content-around"
        >
          <div
            v-if="rewards[0].card > 0"
            class="package-icon package-bonus-icon-card"
          >
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div
            v-if="rewards[0].elixir > 0"
            class="package-icon package-bonus-icon-elixir"
          >
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[0].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
        <div
          class="submit-button to-center-abs"
          @click="buyItem(0, 'Offer', 'Daily')"
        >
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big position-relative"
              >{{ formatPrice(price[0]) }} €

              <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
                {{ formatPrice(oldPrices[0]) }} €
                <div class="offer-red-line to-center-abs"></div>
              </div>
              <div
                v-if="moreContent && moreContent[0]"
                class="big-badge-more-percent smaller"
              >
                <span class="big">{{ moreContent[0] }}</span>
                {{ $t("shop.offers.more_contents2") }}
              </div>
            </span>
          </div>
        </div>
      </div>

      <div v-if="rewards[1]" class="offer-reward-container2">
        <div class="w-100 position-relative mb-3">
          <div class="icon-gold1 to-center-abs">
            <BaseAsset asset="primary" size="big" />
          </div>
          <div class="icon-gold2 to-center-abs">
            <BaseAsset asset="primary" size="big" />
          </div>
        </div>
        <div
          :style="{ visibility: rewards[1].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div
            class="yellow-text bold mt-3"
            style="font-size: 32px"
            :class="rewards[1].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[1].gold) }}
          </div>
        </div>
        <div
          class="d-inline-flex align-items-center"
          :style="{ visibility: rewards[1].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text bold" style="font-size: 32px">
            {{ formatNumber(rewards[1].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="yellow-text ms-1" />
        </div>

        <div
          class="d-inline-flex align-items-center justify-content-around w-75"
        >
          <div v-if="rewards[1].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].card) }} x
            </div>
          </div>

          <div v-if="rewards[1].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[1].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[1].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="text">
              {{ Math.round(rewards[1].expbooster / 60) }} Min.
            </div>
          </div>
        </div>

        <div
          class="submit-button to-center-abs"
          @click="buyItem(1, 'Offer', 'Daily')"
        >
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big position-relative"
              >{{ formatPrice(price[1]) }} €

              <div v-if="oldPrices && oldPrices[1]" class="offer-old-price">
                {{ formatPrice(oldPrices[1]) }} €
                <div class="offer-red-line to-center-abs"></div>
              </div>
              <div
                v-if="moreContent && moreContent[1]"
                class="big-badge-more-percent smaller"
              >
                <span class="big">{{ moreContent[1] }}</span>
                {{ $t("shop.offers.more_contents2") }}
              </div>
            </span>
          </div>
        </div>
      </div>

      <div v-if="rewards[2]" class="offer-reward-container3">
        <img
          :src="$whitelabel.currencies.primary.triple"
          style="width: 200px"
        />
        <div
          class="w-100"
          :style="{ visibility: rewards[2].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div
            class="yellow-text bold"
            style="font-size: 50px"
            :class="rewards[2].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[2].gold) }}
          </div>
        </div>

        <div
          class="d-inline-flex align-items-center mx-auto justify-content-center w-100"
          :style="{ visibility: rewards[2].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="amount-crown yellow-text">
            {{ formatNumber(rewards[2].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-1 yellow-text" />
        </div>

        <div class="d-flex w-100 justify-content-around">
          <div v-if="rewards[2].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].card) }} x
            </div>
          </div>

          <div v-if="rewards[2].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[2].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[2].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[2].expbooster / 60) }} Min.
            </div>
          </div>
        </div>

        <div
          class="submit-button to-center-abs"
          @click="buyItem(2, 'Offer', 'Daily')"
        >
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big position-relative"
              >{{ formatPrice(price[2]) }} €

              <div v-if="oldPrices && oldPrices[2]" class="offer-old-price">
                {{ formatPrice(oldPrices[2]) }} €
                <div class="offer-red-line to-center-abs"></div>
              </div>
              <div
                v-if="moreContent && moreContent[2]"
                class="big-badge-more-percent"
              >
                <span class="big">{{ moreContent[2] }}</span>
                {{ $t("shop.offers.more_contents2") }}
              </div>
            </span>
          </div>
        </div>
      </div>
      <div class="badge-big">
        <span>
          <i18next :translation="$t('shop.offers.badge4a')"
            ><template #0
              ><div class="big">
                {{ $t("shop.offers.badge4.today") }}
              </div></template
            ></i18next
          ></span
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferDaily3",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-daily-3");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-daily-3 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/offer-overlay-daily-dealdestages-3-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: -10px;
    right: -10px;
  }

  .headline {
    position: absolute;
    top: -40px;
    left: -35px;
  }
  .offer-reward-container1 {
    top: 186px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    height: 280px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .icon-gold {
      width: 80px;
      margin-left: auto;
      margin-right: auto;
    }

    .submit-button {
      width: 160px;
      height: 64px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      bottom: -77px;
      left: -63px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 30px;
      }

      .offer-old-price {
        top: -30px;
        right: -140px;
      }
      .big-badge-more-percent {
        top: -390px;
        right: -130px;
      }
    }
  }

  .offer-reward-container2 {
    top: 155px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    right: 51px;
    height: 345px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .icon-gold1 {
      z-index: 1;
      right: -55px;
      width: 64px;
    }
    .icon-gold2 {
      z-index: 2;
      width: 80px;
      top: -15px;
      right: 20px;
    }

    .submit-button {
      width: 200px;
      height: 72px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      bottom: -80px;
      left: -49px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 36px;
      }

      .offer-old-price {
        top: -35px;
        right: -130px;
      }
      .big-badge-more-percent {
        top: -440px;
        right: -180px;
      }
    }
  }

  .offer-reward-container3 {
    top: 110px;
    line-height: 1.2;
    text-align: center;
    width: 330px;
    position: absolute;
    left: 287px;
    height: 383px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .submit-button {
      width: 352px;
      height: 182px;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      padding-left: 28%;
      padding-right: 23%;
      display: inline-flex;
      line-height: 1;
      bottom: -150px;
      left: -40px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }

      .offer-old-price {
        top: -35px;
        right: -130px;
      }
      .big-badge-more-percent {
        top: -460px;
        right: -220px;
      }
    }
  }
  .badge-big {
    width: 194px;
    height: 194px;
    background: url(~@/assets/img/shop/offers/badge-big-back4.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 25.2px;
    color: white;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px $btn-drop-shadow);
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 0;
    line-height: 1.2;
    position: absolute;
    right: 60px;
    top: -30px;
    transform: rotate(8deg);

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 43.2px;
    }
  }
}
</style>
