<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-halloween-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="
            require(`@/assets/img/shop/offers/offer-overlay-event-halloween-1-panel-hl-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container to-center-abs">
        <div
          class="text-center"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        ></div>

        <div
          class="d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 70px">
            {{ formatNumber(rewards[0].gold) }}
          </div>
          <BaseAsset asset="primary" size="big" class="ms-2 yellow-text" />
        </div>

        <div
          class="d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 56px">
            {{ formatNumber(rewards[0].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-2 yellow-text" />
        </div>

        <div
          class="w-50 mx-auto align-items-center justify-content-between d-inline-flex"
        >
          <div
            v-if="rewards[0].card > 0"
            class="d-flex flex-column align-items-center"
          >
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div
            v-if="rewards[0].loyalty > 0"
            class="d-flex flex-column align-items-center"
          >
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div
            v-if="rewards[0].elixir > 0"
            class="d-flex flex-column align-items-center"
          >
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div
            v-if="rewards[0].expbooster > 0"
            class="d-flex flex-column align-items-center"
          >
            <BaseAsset asset="booster" class="h-100" />
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>

        <div
          class="submit-button"
          @click="buyItem(0, 'Offer', 'Event', closeThisModal)"
        >
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big position-relative"
              >{{ formatPrice(price[0]) }} €

              <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
                {{ formatPrice(oldPrices[0]) }} €
                <div class="offer-red-line to-center-abs"></div>
              </div>
              <div
                v-if="moreContent && moreContent[0]"
                class="big-badge-more-percent smaller"
              >
                <span class="big">{{ moreContent[0] }}</span>
                {{ $t("shop.offers.more_contents2") }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferHalloween1",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-halloween-1");
  },

  methods: {
    closeThisModal() {
      this.$bvModal.hide("modal-offer-halloween-1");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-halloween-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/offer-overlay-event-halloween-1-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: 0px;
    right: 0px;
  }

  .headline {
    position: absolute;
    top: 30px;
    right: 80px;
  }

  .offer-reward-container {
    top: 190px;
    line-height: 1.2;
    text-align: left;
    width: 700px;
    height: 475px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-left: 20px;
  }

  .info-text {
    font-size: 28px;
    text-transform: uppercase;
    color: white;
    text-shadow: 1px 2px 4px $text-shadow-dark-green;
    font-family: Ubuntu-Bold;
  }

  .submit-button {
    width: 405px;
    height: 182px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 22px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    line-height: 1.2;
    text-shadow: 1px 2px 4px $text-shadow-dark-green;

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 56px;
      text-shadow: 1px 2px 4px $text-shadow-dark-green;
    }

    .offer-old-price {
      top: -75px;
      right: -130px;
    }
    .big-badge-more-percent {
      top: -430px;
      right: -240px;
    }
  }
}
</style>
