<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-bailout-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline to-center-abs">
        <img
          :src="
            require(`@/assets/img/shop/offers/meinekrone-offer-overlay-bailoutgold-1-panel-head-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container position-absolute">
        <img
          :src="$whitelabel.currencies.primary.triple"
          style="width: 250px"
        />

        <div
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text bold" style="font-size: 72px">
            {{ formatNumber(rewards[0].gold) }}
          </div>
        </div>
        <div
          class="w-100 d-inline-flex justify-content-center align-items-center"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text bold" style="font-size: 55px">
            {{ formatNumber(rewards[0].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-1" />
        </div>

        <div
          class="w-75 d-inline-flex align-items-center justify-content-around"
        >
          <div v-if="rewards[0].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[0].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[0].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
        <div class="submit-button" @click="buyItem(0, 'Offer', 'Bailout')">
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big">{{ formatPrice(price) }} €</span>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "../../base/base-asset.vue";
export default {
  name: "ModalOfferBailout1",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return {
      isPipelineOffer: false,
    };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-bailout-1");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-bailout-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    background-image: url("@/assets/img/shop/offers/bailout-primary-1.png");
    background-color: transparent;
  }

  .close-button {
    top: 50px;
    right: 30px;
  }

  .headline {
    width: 663px;
    top: 0;
  }

  .offer-reward-container {
    top: 150px;
    left: 280px;
    line-height: 1.2;
    text-align: center;
    width: 500px;
    height: 575px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .submit-button {
    width: 404px;
    height: 181px;
    background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
    background-size: 100% 100%;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    text-align: center;
    padding-left: 22px;
    display: inline-flex;
    line-height: 1;

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }
}
</style>
