<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-crown_empty-3"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="
            require(`@/assets/img/shop/offers/meinekrone-offer-overlay-bailoutcrowns-3-panels-hl-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container1">
        <div
          v-if="moreContent && moreContent[0]"
          class="big-badge-more-percent smaller"
        >
          <span class="big">{{ moreContent[0] }}</span>
          {{ $t("shop.offers.more_contents2") }}
        </div>
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text bold" style="font-size: 48px">
            {{ formatNumber(rewards[0].crown) }}
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-2 yellow-text" />
        </div>
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div
            class="yellow-text bold"
            style="font-size: 34px"
            :class="rewards[0].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[0].gold) }}
          </div>
          <BaseAsset asset="primary" size="big" class="ms-2 yellow-text" />
        </div>

        <div class="w-100 d-flex justify-content-around">
          <div v-if="rewards[0].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[0].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[0].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            ]
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>

        <div class="w-100">
          <div class="submit-button" @click="buyItem(0, 'Offer', 'CrownEmpty')">
            <div>
              {{ $t("shop.offers.buy_button", { 0: "" }) }}
              <br />
              <span class="big position-relative"
                >{{ formatPrice(price[0]) }} €

                <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
                  {{ formatPrice(oldPrices[0]) }} €
                  <div class="offer-red-line to-center-abs"></div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="rewards[1]" class="offer-reward-container2">
        <div class="top-deal-text to-center-abs">Chance!</div>
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[1].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text bold" style="font-size: 54px">
            {{ formatNumber(rewards[1].crown) }}
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-2 yellow-text" />
        </div>
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[1].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div
            class="yellow-text bold"
            style="font-size: 34px"
            :class="rewards[1].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[1].gold) }}
          </div>
          <BaseAsset asset="primary" size="big" class="ms-2 yellow-text" />
        </div>

        <div class="w-100 d-flex justify-content-around">
          <div v-if="rewards[1].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].card) }} x
            </div>
          </div>

          <div v-if="rewards[1].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[1].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[1].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[1].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
        <div class="w-100 position-relative">
          <div class="submit-button" @click="buyItem(1, 'Offer', 'CrownEmpty')">
            <div>
              {{ $t("shop.offers.buy_button", { 0: "" }) }}
              <br />
              <span class="big position-relative"
                >{{ formatPrice(price[1]) }} €

                <div v-if="oldPrices && oldPrices[1]" class="offer-old-price">
                  {{ formatPrice(oldPrices[1]) }} €
                  <div class="offer-red-line to-center-abs"></div>
                </div>
                <div
                  v-if="moreContent && moreContent[1]"
                  class="big-badge-more-percent smaller"
                >
                  <span class="big">{{ moreContent[1] }}</span>
                  {{ $t("shop.offers.more_contents2") }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="rewards[2]" class="offer-reward-container3">
        <div class="top-deal-text to-center-abs">
          {{ $t("shop.offers.top_deal") }}
        </div>
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[2].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 60px">
            {{ formatNumber(rewards[2].crown) }}
          </div>
          <img
            :src="$whitelabel.currencies.secondary.large"
            style="width: 80px"
            class="ms-2 yellow-text"
          />
        </div>
        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[2].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div
            class="bold yellow-text"
            style="font-size: 40px"
            :class="rewards[2].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[2].gold) }}
          </div>
          <BaseAsset asset="primary" size="big" class="ms-2 yellow-text" />
        </div>

        <div class="w-100 d-flex justify-content-around">
          <div v-if="rewards[2].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].card) }} x
            </div>
          </div>

          <div v-if="rewards[2].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[2].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[2].expbooster > 0">
            <BaseAsset asset="booster" class="h-100" />
            <div class="primary-accent">
              {{ Math.round(rewards[2].expbooster / 60) }} Min.
            </div>
          </div>
        </div>

        <div class="w-100 position-relative d-flex">
          <div class="submit-button" @click="buyItem(2, 'Offer', 'CrownEmpty')">
            <div>
              {{ $t("shop.offers.buy_button", { 0: "" }) }}
              <br />
              <span class="big position-relative"
                >{{ formatPrice(price[2]) }} €

                <div v-if="oldPrices && oldPrices[2]" class="offer-old-price">
                  {{ formatPrice(oldPrices[2]) }} €
                  <div class="offer-red-line to-center-abs"></div>
                </div>
                <div
                  v-if="moreContent && moreContent[2]"
                  class="big-badge-more-percent"
                >
                  <span class="big">{{ moreContent[2] }}</span>
                  {{ $t("shop.offers.more_contents2") }}
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferCrownEmpty3",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: false };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-crown_empty-3");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-crown_empty-3 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background-size: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-image: url("@/assets/img/shop/offers/bailout-secondary-3.png");
    background-color: transparent;
  }

  .close-button {
    top: -10px;
    right: 0px;
  }

  .headline {
    position: absolute;
    top: 0px;
    left: 10px;
  }

  .offer-reward-container1 {
    top: 280px;
    line-height: 1.2;
    text-align: center;
    width: 280px;
    height: 330px;
    position: absolute;
    left: 21px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    .big-badge-more-percent {
      top: -100px;
      right: -0;
    }

    .submit-button {
      width: 200px;
      height: 72px;
      background: url(~@/assets/img/shop/offers/btn-pay-secondary-offer.png)
        no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      margin-top: 10px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 36px;
      }

      .offer-old-price {
        bottom: 30px;
        right: -130px;
      }
    }
  }

  .offer-reward-container2 {
    top: 245px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    height: 340px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    right: 36px;

    .top-deal-text {
      color: #7d3107;
      font-family: Ubuntu-Bold;
      font-size: 26px;
      text-transform: uppercase;
      text-align: center;
      text-shadow: 0 0 6px white, 0 0 6px white, 0 0 6px white, 0 0 5px white,
        0 0 2px white;
      top: -74px;
    }

    .submit-button {
      width: 200px;
      height: 72px;
      background: url(~@/assets/img/shop/offers/btn-pay-secondary-offer.png)
        no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      margin-top: 10px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 36px;
      }

      .offer-old-price {
        bottom: 30px;
        right: -130px;
      }
      .big-badge-more-percent {
        top: -388px;
        right: -150px;
      }
    }
  }

  .offer-reward-container3 {
    top: 190px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    height: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 330px;

    .top-deal-text {
      color: #7d3107;
      font-family: Ubuntu-Bold;
      font-size: 33px;
      text-transform: uppercase;
      text-align: center;
      text-shadow: 0 0 6px white, 0 0 6px white, 0 0 6px white, 0 0 5px white,
        0 0 2px white;
      top: -94px;
    }

    .submit-button {
      width: 352px;
      height: 182px;
      background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }

      .offer-old-price {
        bottom: 67px;
        right: -130px;
      }

      .big-badge-more-percent {
        top: -518px;
        right: -200px;
      }
    }
  }
}
</style>
