<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-lobby-2"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="
            require(`@/assets/img/shop/offers/offer-gluecksritter-hl-2-panel-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container1">
        <BaseAsset
          asset="primary"
          size="big"
          style="width: 80px; height: 80px"
        ></BaseAsset>
        <div
          class="w-100"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 40px">
            {{ formatNumber(rewards[0].gold) }}
          </div>
        </div>

        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text" style="font-size: 30px">
            {{ formatNumber(rewards[0].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-1 yellow-text" />
        </div>

        <div
          class="w-100 d-inline-flex align-items-center justify-content-around"
        >
          <div v-if="rewards[0].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[0].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[0].expbooster > 0">
            <BaseAsset asset="booster" class="h-100" />
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
        <div class="submit-button" @click="buyItem(0, 'Offer', 'Lobby')">
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big">{{ formatPrice(price[0]) }} €</span>
          </div>
        </div>
      </div>

      <div v-if="rewards[1]" class="offer-reward-container2">
        <img
          :src="$whitelabel.currencies.primary.triple"
          style="width: 200px"
        />
        <div
          :style="{ visibility: rewards[1].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text bold" style="font-size: 50px">
            {{ formatNumber(rewards[1].gold) }}
          </div>
        </div>

        <div
          class="w-100 d-inline-flex align-items-center justify-content-center"
          :style="{ visibility: rewards[1].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="yellow-text bold" style="font-size: 40px">
            {{ formatNumber(rewards[1].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-1 yellow-text" />
        </div>

        <div
          class="d-inline-flex align-items-center justify-content-around w-100"
        >
          <div v-if="rewards[1].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].card) }} x
            </div>
          </div>

          <div v-if="rewards[1].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[1].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[1].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[1].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
        <div class="submit-button" @click="buyItem(1, 'Offer', 'Lobby')">
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big">{{ formatPrice(price[1]) }} €</span>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferLobby2",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-lobby-2");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-lobby-2 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/kleinekrone-offer-overlay-lobby-gluecksritter-2-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: 50px;
    right: -40px;
  }

  .headline {
    position: absolute;
    top: -10px;
    left: 0px;
  }

  .offer-reward-container1 {
    top: 220px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    left: 220px;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .submit-button {
      width: 200px;
      height: 72px;
      background: url(~@/assets/img/shop/offers/btn-pay-secondary-offer.png)
        no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.1;
      bottom: -90px;
      text-shadow: 1px 2px 4px $text-shadow-dark-green;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 36px;
      }
    }
  }

  .offer-reward-container2 {
    top: 190px;
    line-height: 1.2;
    text-align: center;
    width: 350px;
    position: absolute;
    right: 90px;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .submit-button {
      width: 342px;
      height: 154px;
      background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      padding-left: 28%;
      padding-right: 23%;
      padding-top: 2px;
      display: inline-flex;
      line-height: 1;
      bottom: -120px;
      text-shadow: 3px 3px 4px $text-shadow-dark-green;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }
    }
  }
}
</style>
