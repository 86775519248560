<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-levelup-2"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container1">
        <b-row class="position-relative h-100">
          <b-col cols="12">
            <BaseAsset
              asset="primary"
              size="big"
              style="width: 66px; height: 66px"
            />
          </b-col>
          <b-col
            cols="12"
            :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div class="yellow-text bold" style="font-size: 40px">
              {{ formatNumber(rewards[0].gold) }}
            </div>
          </b-col>

          <b-col cols="12">
            <b-row align-h="center" align-v="center" class="mx-3">
              <b-col v-if="rewards[0].crown > 0" cols="3" class="p-0">
                <BaseAsset asset="secondary" size="big"></BaseAsset>
                <div class="primary-accent">
                  {{ formatNumber(rewards[0].crown) }} x
                </div>
              </b-col>
              <b-col v-if="rewards[0].card > 0" cols="3" class="p-0">
                <BaseAsset asset="cards" size="big" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[0].card) }} x
                </div>
              </b-col>

              <b-col v-if="rewards[0].loyalty > 0" cols="3" class="p-0">
                <BaseAsset asset="loyalty" size="big" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[0].loyalty) }} x
                </div>
              </b-col>

              <b-col v-if="rewards[0].elixir > 0" cols="3" class="p-0">
                <BaseAsset asset="tertiary" size="big" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[0].elixir) }} x
                </div>
              </b-col>

              <b-col v-if="rewards[0].expbooster > 0" cols="3" class="p-0">
                <BaseAsset asset="booster" size="big" />
                <div class="primary-accent">
                  {{ Math.round(rewards[0].expbooster / 60) }} Min.
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <div class="submit-button" @click="buyItem(0, 'Offer', 'LevelUp')">
              <div>
                {{ $t("shop.offers.buy_button", { 0: "" }) }}
                <br />
                <span class="big">{{ formatPrice(price[0]) }} €</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-if="rewards[1]" class="offer-reward-container2">
        <b-row>
          <div class="top-deal-text to-center-abs">
            {{ $t("shop.offers.top_deal") }}
          </div>
          <b-col cols="12">
            <img
              :src="$whitelabel.currencies.primary.triple"
              style="width: 200px"
            />
          </b-col>
          <b-col
            cols="12"
            :style="{ visibility: rewards[1].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div class="bold yellow-text" style="font-size: 54px">
              {{ formatNumber(rewards[1].gold) }}
            </div>
          </b-col>

          <b-col cols="12">
            <b-row align-h="center" align-v="center">
              <b-col v-if="rewards[1].crown > 0" cols="3" class="p-0">
                <BaseAsset asset="secondary" size="big"></BaseAsset>
                <div class="primary-accent">
                  {{ formatNumber(rewards[1].crown) }} x
                </div>
              </b-col>
              <b-col v-if="rewards[1].card > 0" cols="3" class="p-0">
                <BaseAsset asset="cards" size="big" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[1].card) }} x
                </div>
              </b-col>

              <b-col v-if="rewards[1].loyalty > 0" cols="3" class="p-0">
                <BaseAsset asset="loyalty" size="big" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[1].loyalty) }} x
                </div>
              </b-col>

              <b-col v-if="rewards[1].elixir > 0" cols="3" class="p-0">
                <BaseAsset asset="tertiary" size="big" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[1].elixir) }} x
                </div>
              </b-col>

              <b-col v-if="rewards[1].expbooster > 0" cols="3" class="p-0">
                <BaseAsset asset="booster" size="big" />
                <div class="primary-accent">
                  {{ Math.round(rewards[1].expbooster / 60) }} Min.
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="position-relative">
            <div
              class="submit-button to-center-abs"
              @click="buyItem(1, 'Offer', 'LevelUp')"
            >
              <div>
                {{ $t("shop.offers.buy_button", { 0: "" }) }}
                <br />
                <span class="big">{{ formatPrice(price[1]) }} €</span>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferLevelup2",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: false };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-levelup-2");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-levelup-2 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom center;
    background-image: url("@/assets/img/shop/offers/levelup-2.png");
    background-color: transparent;
  }

  .close-button {
    top: 50px;
    right: 30px;
  }

  .offer-reward-container1 {
    top: 235px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    left: 227px;
    height: 375px;

    .amount-crown {
      font-size: 30px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-left: -48px;

      .icon-crown {
        position: absolute;
        right: -50px;
        top: -2px;
      }
    }

    .bonus-col {
      margin-top: 10px;
      padding: 0 10%;

      .package-bonus-icons {
        padding: 0 10px;
        white-space: nowrap;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 46px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .submit-button {
      width: 200px;
      height: 72px;
      background: url(~@/assets/img/shop/offers/btn-pay-secondary-offer.png)
        no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.2;
      margin-top: 15px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 36px;
      }
    }
  }

  .offer-reward-container2 {
    top: 190px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    right: 116px;

    .top-deal-text {
      color: #7d3107;
      font-family: Ubuntu-Bold;
      font-size: 33px;
      text-transform: uppercase;
      text-align: center;
      text-shadow: 0 0 6px white, 0 0 6px white, 0 0 6px white, 0 0 5px white,
        0 0 2px white;
      top: -100px;
    }

    .submit-button {
      width: 352px;
      height: 182px;
      background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      padding-left: 28%;
      padding-right: 23%;
      display: inline-flex;
      line-height: 1;
      left: -17px;
      top: -15px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }
    }
  }
}
</style>
