<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-lobby-3"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="$whitelabel.assets.offers.lobby3[$store.state.locale]"
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container1">
        <BaseAsset
          asset="primary"
          size="big"
          style="width: 80px; height: 80px"
        />
        <div v-if="rewards[0].gold > 0">
          <div
            class="bold yellow-text"
            style="font-size: 36px"
            :class="rewards[0].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[0].gold) }}
          </div>
        </div>

        <div
          v-if="rewards[0].crown > 0"
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <div class="bold yellow-text" style="font-size: 30px">
            {{ formatNumber(rewards[0].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-1" />
        </div>

        <div
          class="d-inline-flex align-items-center justify-content-around w-100 px-3"
        >
          <div v-if="rewards[0].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[0].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[0].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
        <div class="submit-button" @click="buyItem(0, 'Offer', 'Lobby')">
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big position-relative"
              >{{ formatPrice(price[0]) }} €

              <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
                {{ formatPrice(oldPrices[0]) }} €
                <div class="offer-red-line to-center-abs"></div>
              </div>
              <div
                v-if="moreContent && moreContent[0]"
                class="big-badge-more-percent smaller"
              >
                <span class="big">{{ moreContent[0] }}</span>
                {{ $t("shop.offers.more_contents2") }}
              </div>
            </span>
          </div>
        </div>
      </div>

      <div v-if="rewards[1]" class="offer-reward-container2">
        <div class="icon-gold-container">
          <div class="icon-gold1 to-center-abs">
            <BaseAsset
              asset="primary"
              size="big"
              class="w-100 h-100"
            ></BaseAsset>
          </div>
          <div class="icon-gold2 to-center-abs">
            <BaseAsset
              asset="primary"
              size="big"
              class="w-100 h-100"
            ></BaseAsset>
          </div>
        </div>
        <div v-if="rewards[1].gold > 0">
          <div
            class="bold yellow-text"
            style="font-size: 40px"
            :class="rewards[1].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[1].gold) }}
          </div>
        </div>
        <div
          v-if="rewards[1].crown > 0"
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <div class="bold yellow-text" style="font-size: 30px">
            {{ formatNumber(rewards[1].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-1" />
        </div>

        <div
          class="d-inline-flex align-items-center justify-content-around w-100 px-3"
        >
          <div v-if="rewards[1].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].card) }} x
            </div>
          </div>

          <div v-if="rewards[1].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[1].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[1].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[1].expbooster > 0">
            <BaseAsset asset="booster" class="h-100" />
            <div class="primary-accent">
              {{ Math.round(rewards[1].expbooster / 60) }} Min.
            </div>
          </div>
        </div>

        <div class="submit-button" @click="buyItem(1, 'Offer', 'Lobby')">
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big position-relative"
              >{{ formatPrice(price[1]) }} €

              <div v-if="oldPrices && oldPrices[1]" class="offer-old-price">
                {{ formatPrice(oldPrices[1]) }} €
                <div class="offer-red-line to-center-abs"></div>
              </div>
              <div
                v-if="moreContent && moreContent[1]"
                class="big-badge-more-percent smaller"
              >
                <span class="big">{{ moreContent[1] }}</span>
                {{ $t("shop.offers.more_contents2") }}
              </div>
            </span>
          </div>
        </div>
      </div>

      <div v-if="rewards[2]" class="offer-reward-container3">
        <img
          :src="$whitelabel.currencies.primary.triple"
          style="width: 250px"
        />
        <div v-if="rewards[2].gold > 0">
          <div
            class="yellow-text bold"
            style="font-size: 50px"
            :class="rewards[2].gold.toString().length > 9 ? 'small' : ''"
          >
            {{ formatNumber(rewards[2].gold) }}
          </div>
        </div>

        <div
          v-if="rewards[2].crown > 0"
          class="w-100 d-inline-flex align-items-center justify-content-center"
        >
          <div class="bold yellow-text" style="font-size: 40px">
            {{ formatNumber(rewards[2].crown) }} x
          </div>
          <BaseAsset asset="secondary" size="big" class="ms-1" />
        </div>

        <div
          class="d-inline-flex align-items-center justify-content-around w-100 px-3 mt-3"
        >
          <div v-if="rewards[2].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].card) }} x
            </div>
          </div>

          <div v-if="rewards[2].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[2].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[2].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[2].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[2].expbooster / 60) }} Min.
            </div>
          </div>
        </div>

        <div class="submit-button" @click="buyItem(2, 'Offer', 'Lobby')">
          <div>
            {{ $t("shop.offers.buy_button", { 0: "" }) }}
            <br />
            <span class="big position-relative"
              >{{ formatPrice(price[2]) }} €

              <div v-if="oldPrices && oldPrices[2]" class="offer-old-price">
                {{ formatPrice(oldPrices[2]) }} €
                <div class="offer-red-line to-center-abs"></div>
              </div>
              <div
                v-if="moreContent && moreContent[2]"
                class="big-badge-more-percent smaller"
              >
                <span class="big">{{ moreContent[2] }}</span>
                {{ $t("shop.offers.more_contents2") }}
              </div>
            </span>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferLobby3",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-lobby-3");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-lobby-3 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/meinekrone-offer-overlay-lobby-joetztodernie-3-panels.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: -10px;
    right: -10px;
  }
}
</style>

<style scoped lang="scss">
#modal-offer-lobby-3 {
  .headline {
    position: absolute;
    top: -46px;
    left: -58px;
  }
  .offer-reward-container1 {
    top: 220px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    left: 6px;
    height: 370px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .submit-button {
      width: 160px;
      height: 64px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.3;
      margin-right: 50px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 30px;
      }

      .offer-old-price {
        top: -40px;
        right: -160px;
        padding: 0;
      }
      .big-badge-more-percent {
        top: -390px;
        right: -130px;
      }
    }
  }

  .offer-reward-container2 {
    top: 205px;
    line-height: 1.2;
    text-align: center;
    width: 300px;
    position: absolute;
    right: 10px;
    height: 415px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .icon-gold-container {
      height: 75px;

      .icon-gold1 {
        z-index: 1;
        right: -55px;
        width: 64px;
      }
      .icon-gold2 {
        z-index: 2;
        width: 80px;
        top: -15px;
        right: 20px;
      }
    }

    .bonus-col {
      padding: 0 10%;

      .package-bonus-icons {
        white-space: nowrap;
        margin-left: -10px;
        display: flex;
        align-items: center;
        justify-content: center;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 42px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 42px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 42px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 42px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .submit-button {
      width: 200px;
      height: 72px;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1.3;
      margin-right: 60px;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 36px;
      }

      .offer-old-price {
        top: -40px;
        right: -150px;
        padding: 0;
      }
      .big-badge-more-percent {
        top: -460px;
        right: -160px;
      }
    }
  }

  .offer-reward-container3 {
    top: 150px;
    line-height: 1.2;
    text-align: center;
    width: 340px;
    position: absolute;
    left: 310px;
    height: 525px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    .submit-button {
      width: 352px;
      height: 182px;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      padding-left: 28%;
      padding-right: 23%;
      display: inline-flex;
      line-height: 1.1;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }

      .offer-old-price {
        top: -60px;
        right: -130px;
        padding: 0;
      }
      .big-badge-more-percent {
        top: -480px;
        right: -190px;
      }
    }
  }
}
</style>
