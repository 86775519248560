<template>
  <div id="preloader" style="display: none"></div>
</template>
<script>
import AnimationFirework from "../animation/animation-firework";
import AnimationEffects from "../animation/animation-effects";
export default {
  name: "Preloader",
  mixins: [AnimationFirework, AnimationEffects],
  mounted: function () {
    //preload important images
    let imageSrc = this.getEssentialImages();
    this.preloadImages(imageSrc);

    //preload important sounds
    let soundSrc = this.getEssentialSounds();
    this.preloadSounds(soundSrc);
  },

  methods: {
    preloadImages: function (src) {
      let preloader = document.getElementById("preloader");
      for (let i = 0; i < src.length; i++) {
        let img = new Image();
        img.src = src[i];
        preloader.appendChild(img);
      }
    },
    preloadSounds: function (src) {
      let preloader = document.getElementById("preloader");
      for (let i = 0; i < src.length; i++) {
        let audio = new Audio(src[i]);
        audio.id = src[i];
        preloader.appendChild(audio);
      }
    },

    getEssentialImages: function () {
      return [
        require("@/assets/img/animation/effects/adelsliga-animation-abstieg-smoke-0.png"),
        require("@/assets/img/animation/effects/adelsliga-animation-abstieg-smoke-1.png"),
        require("@/assets/img/animation/effects/adelsliga-animation-abstieg-smoke-2.png"),
        require("@/assets/img/animation/effects/adelsliga-animation-abstieg-smoke-3.png"),
        require("@/assets/img/animation/effects/adelsliga-animation-abstieg-smoke-4.png"),
        require("@/assets/img/animation/effects/adelsliga-animation-abstieg-smoke-5.png"),
        require("@/assets/img/animation/effects/adelsliga-animation-abstieg-smoke-6.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-sprites-MorphCountFX.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-0.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-1.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-2.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-3.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-4.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-5.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-6.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-7.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-8.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-9.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-10.png"),
        require("@/assets/img/animation/effects/kleinekrone-animation-rewardshow-cards-spritesheet-smoketrail-11.png"),
        require("@/assets/img/animation/effects/koenigsweg-keylockexplode-smokesmall.png"),

        require("@/assets/img/animation/firework/adelsliga-aufstieg-firework-0.png"),
        require("@/assets/img/animation/firework/adelsliga-aufstieg-firework-1.png"),
        require("@/assets/img/animation/firework/adelsliga-aufstieg-firework-2.png"),
        require("@/assets/img/animation/firework/adelsliga-aufstieg-firework-3.png"),

        require("@/assets/img/animation/firework/animation-firework-medium-0.png"),
        require("@/assets/img/animation/firework/animation-firework-medium-1.png"),

        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-0.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-1.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-2.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-3.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-4.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-5.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-6.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-7.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-8.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-9.png"),
        require("@/assets/img/animation/firework/kleinekrone-animation-firework-big-blue-10.png"),
      ];
    },
    getEssentialSounds: function () {
      return [
        require("@/assets/sound/animation/full-reward-earned/small.wav"),
        require("@/assets/sound/animation/full-reward-earned/gold.wav"),
        require("@/assets/sound/animation/full-reward-earned/achievement.wav"),
        require("@/assets/sound/animation/full-reward-earned/counter.mp3"),
        require("@/assets/sound/animation/full-reward-earned/magic.mp3"),
        require("@/assets/sound/animation/full-reward-earned/morph.mp3"),
        require("@/assets/sound/animation/full-reward-earned/kleinekrone-reward-epiccard-additionalfanfare.mp3"),
        require("@/assets/sound/animation/ewige-erfolge-fanfare.mp3"),
        require("@/assets/sound/animation/adelsliga-abstieg.mp3"),
        require("@/assets/sound/animation/adelsliga-aufstieg-fanfare.mp3"),
        require("@/assets/sound/animation/kleinekrone-animations-soundFX-reward-fanfare-2.mp3"),
        "/assets/spines/bonus-slot/kleinekrone-animations-mainsound-magic-button.mp3",
        "/assets/spines/bonus-slot/kleinekrone-animations-mainsound-magic-reveal.mp3",
        "/assets/spines/bonus-slot/kleinekrone-animations-soundFX-potion-active-backgroundatmo.mp3",
        require("@/assets/sound/animation/elixir/kleinekrone-animations-soundFX-potion-active-bubbles.mp3"),
        "/assets/spines/bonus-slot/kleinekrone-animations-soundFX-potion-active-electricbuzz.mp3",
        require("@/assets/sound/daily/kleinekrone-cardflip-sound-with-magic.mp3"),
        require("@/assets/sound/kingspath/1-start.mp3"),
        require("@/assets/sound/kingspath/2-mainspinloop.wav"),
        require("@/assets/sound/kingspath/3-ending.mp3"),
        require("@/assets/sound/kingspath/win2.wav"),
        require("@/assets/sound/general/atmo-1.wav"),
        require("@/assets/sound/general/oh-michi.ogg"),
        require("@/assets/sound/general/atmo-3.wav"),
        require("@/assets/sound/general/atmo-4.wav"),
        require("@/assets/sound/general/sound-1.wav"),
        require("@/assets/sound/general/sound-2.wav"),
        require("@/assets/sound/general/sound-3.wav"),
        require("@/assets/sound/general/sound-5.wav"),
        require("@/assets/sound/general/sound-8.wav"),
        require("@/assets/sound/general/sound-10.wav"),
        require("@/assets/sound/general/sound-11.wav"),
        require("@/assets/sound/general/sound-12.wav"),
      ];
    },
  },
};
</script>
<style scoped lang="scss">
#preloader {
  display: none;
  img {
    width: 1px;
    height: 1px;
  }
}
</style>
