<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-ab-testing"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline"></div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container1">
        <div class="position-relative w-100">
          <div class="icon-gold">
            <img :src="$whitelabel.currencies.primary.triple" class="w-100" />
          </div>
          <div v-if="rewards[0].gold > 0" class="w-100">
            <div class="amount-gold glow-text">
              {{ formatNumber(rewards[0].gold) }}
            </div>
          </div>

          <div class="w-100 bonus-col">
            <div class="package-bonus-icons">
              <div
                v-if="rewards[0].card > 0"
                class="package-icon package-bonus-icon-card d-inline-flex align-items-center justify-content-center"
              >
                <div class="text glow-text">
                  {{ formatNumber(rewards[0].card) }} x
                </div>
                <div class="icon">
                  <img
                    :src="$whitelabel.assets.features.cards.icon"
                    class="h-100"
                  />
                </div>
              </div>

              <div
                v-if="rewards[0].loyalty > 0"
                class="package-icon package-bonus-icon-loyalty d-inline-flex align-items-center justify-content-center"
              >
                <div class="text glow-text">
                  {{ formatNumber(rewards[0].loyalty) }} x
                </div>
                <div class="icon">
                  <BaseAsset asset="loyalty" size="big" />
                </div>
              </div>

              <div
                v-if="rewards[0].expbooster > 0"
                class="col package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <BaseAsset asset="booster" size="big" />
                </div>
                <div class="text glow-text">
                  {{ Math.round(rewards[0].expbooster / 60) }} Min.
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="submit-button to-center-abs"
          @click="buyItem(0, 'Offer', 'Lobby')"
        >
          <div class="pe-0">
            <div class="text green-text">
              {{ $t("shop.offers.buy_button2") }}
            </div>
          </div>

          <div class="price green-text position-relative">
            {{ formatPrice(price[0]) }} €
          </div>

          <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
            {{ formatPrice(oldPrices[0]) }} €
            <div class="offer-red-line to-center-abs"></div>
          </div>
          <div
            v-if="moreContent && moreContent[0]"
            class="big-badge-more-percent smaller"
          >
            <span class="big">{{ moreContent[0] }}</span>
            {{ $t("shop.offers.more_contents2") }}
          </div>
        </div>
      </div>

      <div v-if="rewards[1]" class="offer-reward-container2 container">
        <div class="top-text glow-text to-center-abs">
          {{ $t("shop.offers.best_offer") }}
        </div>
        <div class="position-relative w-100 h-100">
          <div class="w-100">
            <div class="icon-gold-container position-relative">
              <div class="icon-gold to-center-abs">
                <img
                  :src="$whitelabel.currencies.primary.triple"
                  class="w-100"
                />
              </div>
            </div>
          </div>
          <div v-if="rewards[1].gold > 0" class="w-100">
            <div class="amount-gold glow-text">
              {{ formatNumber(rewards[1].gold) }}
            </div>
          </div>

          <div class="w-100 bonus-col">
            <div class="package-bonus-icons">
              <div
                v-if="rewards[1].card > 0"
                class="package-icon package-bonus-icon-card"
              >
                <div class="icon">
                  <img
                    :src="$whitelabel.assets.features.cards.icon"
                    class="h-100"
                  />
                </div>
                <div class="text glow-text">
                  {{ formatNumber(rewards[1].card) }} x
                </div>
              </div>

              <div
                v-if="rewards[1].loyalty > 0"
                class="package-icon package-bonus-icon-loyalty"
              >
                <div class="icon">
                  <BaseAsset asset="loyalty" size="big" />
                </div>
                <div class="text glow-text">
                  {{ formatNumber(rewards[1].loyalty) }} x
                </div>
              </div>

              <div
                v-if="rewards[1].expbooster > 0"
                class="package-icon package-bonus-icon-expbooster"
              >
                <div class="icon">
                  <BaseAsset asset="booster" size="big" />
                </div>
                <div class="text glow-text">
                  {{ Math.round(rewards[1].expbooster / 60) }} Min.
                </div>
              </div>
            </div>
          </div>

          <div
            class="submit-button to-center-abs d-inline-flex"
            @click="buyItem(1, 'Offer', 'Lobby')"
          >
            <div class="ps-1 pe-0">
              <div class="text big green-text">
                {{ $t("shop.offers.buy_button2") }}
              </div>
            </div>

            <div class="price green-text position-relative">
              {{ formatPrice(price[1]) }} €
            </div>

            <div v-if="oldPrices && oldPrices[1]" class="offer-old-price">
              {{ formatPrice(oldPrices[1]) }} €
              <div class="offer-red-line to-center-abs"></div>
            </div>
            <div
              v-if="moreContent && moreContent[1]"
              class="big-badge-more-percent"
            >
              <span class="big">{{ moreContent[1] }}</span>
              {{ $t("shop.offers.more_contents2") }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "../../base/base-asset.vue";

export default {
  name: "ModalOfferAbTesting",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-ab-testing");
  },
};
</script>
<style lang="scss">
$modal-width: 1280px;
$modal-height: 750px;

#modal-offer-ab-testing {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 800px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/kleinekrone-offer-overlay-big-deal-experimental-2-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: 10px;
    right: 0px;
    width: 168px;
    height: 168px;
    background-image: url(~@/assets/img/shop/offers/btn-close-default.png);
  }
}
</style>

<style scoped lang="scss">
#modal-offer-ab-testing {
  .headline {
    position: absolute;
    top: 22px;
    left: 0;
    width: 761px;
    height: 468px;
    background: url(~@/assets/img/shop/offers/kleinekrone-offer-overlay-big-deal-experimental-logo.png)
      center/100% 100% no-repeat;
    z-index: 1;
  }

  .glow-text {
    text-shadow: 0 0 11px $text-shadow--cyan, 0 0 5px $text-shadow--cyan,
      0 0 5px $text-shadow--cyan, 0 0 3px rgba(0, 94, 110, 0.8),
      0 0 3px rgba(0, 94, 110, 0.8);
  }

  .green-text {
    text-shadow: 0 0 14px rgba(66, 255, 0, 0.7), 0 0 10px rgba(66, 255, 0, 0.7),
      0 0 4px #0b2b00, 0 0 4px #165400;
  }

  .offer-reward-container1 {
    top: 397px;
    line-height: 0;
    text-align: center;
    width: 300px;
    position: absolute;
    left: 305px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    .icon-gold {
      width: 80px;
      margin-left: auto;
      margin-right: auto;
    }

    .amount-gold {
      font-size: 32px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      margin-top: 10px;
      line-height: 1;
    }

    .bonus-col {
      margin-top: 15px;
      padding: 0 10% 0 12%;

      .package-bonus-icons {
        padding: 0 10px;
        white-space: nowrap;
        display: flex;
        justify-content: space-around;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            font-family: Ubuntu-Bold;
            font-size: 26px;
            margin-top: 5px;
          }

          .icon {
            height: 44px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            font-family: Ubuntu-Bold;
            font-size: 26px;
            margin-top: 5px;
          }

          .icon {
            height: 44px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            font-family: Ubuntu;
            font-size: 20px;
            margin-top: 5px;
          }

          .icon {
            height: 44px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .submit-button {
      width: 270px;
      height: 46px;
      left: 26px;
      top: 210px;
      background: url(~@/assets/img/shop/offers/btn-buy-main.png) no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      cursor: pointer;
      line-height: 1.1;

      .text {
        font-family: Ubuntu-Bold;
        color: white;
        font-size: 15px;
      }

      .price {
        font-family: Ubuntu-Bold;
        color: white;
        font-size: 33px;
        margin-top: -4px;
      }

      .offer-old-price {
        top: -35px;
        right: -40px;
        z-index: 1;
      }

      .big-badge-more-percent {
        top: -260px;
        right: -70px;
        position: absolute;
      }

      .red-line {
        width: 70%;
        height: 4px;
        top: 18px;
        left: 10%;
        background-color: $text-red-line;
        transform: rotateZ(15deg);
      }

      .extra-button {
        width: 175px;
        height: 34px;
        top: 80%;
        background: url(~@/assets/img/shop/offers/btn-buy-main.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 18px;
        font-family: Ubuntu-Bold;
      }
    }
  }

  .offer-reward-container2 {
    top: 190px;
    line-height: 1.2;
    text-align: center;
    width: 350px;
    position: absolute;
    left: 700px;
    height: 400px;

    .top-text {
      position: absolute;
      top: -100px;
      left: -22px;
      font-family: Ubuntu-Bold;
      text-transform: uppercase;
      font-size: 35px;
      width: 400px;
      text-align: center;
    }

    .icon-gold-container {
      height: 80px;

      .icon-gold {
        width: 120px;
      }
    }

    .amount-gold {
      font-size: 50px;
      font-family: Ubuntu-Bold;
      position: relative;
      display: inline-flex;
      text-align: center;
      line-height: 1;
      margin-top: 10px;
    }

    .bonus-col {
      margin-top: 35px;
      padding: 0 10%;

      .package-bonus-icons {
        padding: 0 10px;
        white-space: nowrap;
        display: flex;
        justify-content: space-around;
        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            font-family: Ubuntu-Bold;
            font-size: 40px;
            margin-top: 5px;
          }

          .icon {
            height: 65px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            font-family: Ubuntu-Bold;
            font-size: 40px;
            margin-top: 5px;
          }

          .icon {
            height: 65px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        //.package-bonus-icon-elixir {
        //  .text {
        //    font-family: Ubuntu;
        //    font-size: 24px;
        //    margin-top: 5px;
        //  }
        //
        //  .icon {
        //    height: 51px;
        //    margin-left: auto;
        //    margin-right: auto;
        //  }
        //}

        .package-bonus-icon-expbooster {
          .text {
            font-family: Ubuntu;
            font-size: 24px;
            margin-top: 5px;
          }

          .icon {
            height: 51px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    .submit-button {
      width: 423px;
      height: 78px;
      top: 358px;
      left: -20px;
      background: url(~@/assets/img/shop/offers/btn-buy-main.png) no-repeat;
      background-size: 100% 100%;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      cursor: pointer;
      line-height: 1.1;

      .text {
        font-family: Ubuntu-Bold;
        color: white;
        font-size: 15px;

        &.big {
          font-size: 19px;
          width: 140px;
          margin-left: auto;
          margin-right: 15px;
        }
      }

      .price {
        font-family: Ubuntu-Bold;
        color: white;
        font-size: 51px;
        margin-top: -4px;
      }

      .offer-old-price {
        top: -25px;
        right: -40px;
        z-index: 1;
      }
      .big-badge-more-percent {
        top: -430px;
        right: -90px;
        position: absolute;
      }

      .red-line {
        width: 70%;
        height: 4px;
        top: 22px;
        left: 10%;
        background-color: $text-red-line;
        transform: rotateZ(15deg);
      }

      .extra-button {
        width: 269px;
        height: 52px;
        top: 80%;
        background: url(~@/assets/img/shop/offers/btn-buy-main.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 27px;
        font-family: Ubuntu-Bold;
      }
    }
  }
}
</style>
