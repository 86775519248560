<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-daily-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container to-center-abs">
        <b-row class="position-relative w-100">
          <b-col
            cols="12"
            class="d-inline-flex align-items-center justify-content-center"
            :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
          >
            <div class="yellow-text bold" style="font-size: 65px">
              {{ formatNumber(rewards[0].gold) }} x
            </div>
            <BaseAsset
              asset="primary"
              size="big"
              class="ms-2 yellow-text"
              style="width: 66px; height: 66px"
            />
          </b-col>

          <b-col
            cols="12"
            class="d-inline-flex align-items-center justify-content-center"
            :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
          >
            <div class="bold yellow-text" style="font-size: 65px">
              {{ formatNumber(rewards[0].crown) }} x
            </div>
            <img
              :src="$whitelabel.currencies.secondary.large"
              style="width: 66px"
              class="ms-2 yellow-text"
            />
          </b-col>

          <b-col cols="6" class="mx-auto">
            <b-row align-h="around" align-v="center">
              <b-col
                v-if="rewards[0].card > 0"
                cols="3"
                class="p-0 align-items-center text-center"
              >
                <BaseAsset asset="cards" size="big" class="mx-auto" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[0].card) }} x
                </div>
              </b-col>

              <b-col
                v-if="rewards[0].loyalty > 0"
                cols="3"
                class="p-0 align-items-center text-center"
              >
                <BaseAsset asset="loyalty" size="big" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[0].loyalty) }} x
                </div>
              </b-col>

              <b-col
                v-if="rewards[0].elixir > 0"
                cols="3"
                class="p-0 align-items-center text-center"
              >
                <BaseAsset asset="tertiary" size="big" />
                <div class="primary-accent">
                  {{ formatNumber(rewards[0].elixir) }} x
                </div>
              </b-col>

              <b-col
                v-if="rewards[0].expbooster > 0"
                cols="3"
                class="p-0 align-items-center text-center"
              >
                <BaseAsset asset="booster" size="big" />
                <div class="primary-accent">
                  {{ Math.round(rewards[0].expbooster / 60) }} Min.
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <b-row align-h="center" class="w-100">
          <div class="submit-button" @click="buyItem(0, 'Offer', 'Daily')">
            <div>
              {{ $t("shop.offers.buy_button", { 0: "" }) }}
              <br />
              <span class="big position-relative"
                >{{ formatPrice(price[0]) }} €

                <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
                  {{ formatPrice(oldPrices[0]) }} €
                  <div class="offer-red-line to-center-abs"></div>
                </div>
                <div
                  v-if="moreContent && moreContent[0]"
                  class="big-badge-more-percent"
                >
                  <span class="big">{{ moreContent[0] }}</span>
                  {{ $t("shop.offers.more_contents2") }}
                </div>
              </span>
            </div>
          </div>

          <div class="badge-big">
            <span>
              <i18next :translation="$t('shop.offers.badge4a')"
                ><template #0
                  ><div class="big">
                    {{ $t("shop.offers.badge4.today") }}
                  </div></template
                ></i18next
              ></span
            >
          </div></b-row
        >
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferDaily1",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },

  mounted: function () {
    this.$bvModal.show("modal-offer-daily-1");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-daily-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/meinekrone-offer-overlay-daily-kingforaday-1-panel.png)
      no-repeat;
    background-size: 100%;
    background-position: top center;
    padding-top: 20px;
  }

  .close-button {
    top: -10px;
    right: -10px;
  }

  .offer-reward-container {
    top: 210px;
    line-height: 1.2;
    text-align: left;
    width: 700px;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .bonus-col {
      margin-top: 10px;

      .package-bonus-icons {
        padding: 0 25%;
        white-space: nowrap;

        .package-icon {
          width: 93px;
          text-align: center;
        }

        .package-bonus-icon-card {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
            white-space: nowrap;
          }

          .icon {
            height: 46px;
            margin-left: auto;
            margin-right: auto;
          }
        }
        .package-bonus-icon-loyalty {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
            white-space: nowrap;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-elixir {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
            white-space: nowrap;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }

        .package-bonus-icon-expbooster {
          .text {
            color: $primary-accent;
            font-family: Ubuntu;
            font-size: 22px;
            margin-top: 5px;
            white-space: nowrap;
          }

          .icon {
            height: 47px;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }

    .submit-button {
      width: 405px;
      height: 100px;
      align-items: center;
      justify-content: center;
      font-size: 22px;
      color: white;
      text-transform: uppercase;
      cursor: pointer;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      line-height: 1;
      text-shadow: 1px 2px 4px $text-shadow-dark-green;

      .big {
        color: white;
        font-family: Ubuntu-Bold;
        font-size: 56px;
        text-shadow: 1px 2px 4px $text-shadow-dark-green;
      }

      .offer-old-price {
        top: -55px;
        right: -130px;
      }

      .big-badge-more-percent {
        top: -625px;
        right: -435px;
      }
    }

    .badge-big {
      width: 210px;
      height: 210px;
      background: url(~@/assets/img/shop/offers/badge-big-back2.png) no-repeat;
      background-size: cover;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      color: $primary-accent;
      text-transform: uppercase;
      font-family: Ubuntu-Bold;
      text-align: center;
      display: inline-flex;
      filter: drop-shadow(0 2px 4px $btn-drop-shadow);
      padding-left: 35px;
      padding-right: 35px;
      padding-top: 0;
      line-height: 1.2;
      position: absolute;
      right: -25px;
      top: 90px;
      transform: rotate(8deg);

      .big {
        color: $primary-accent;
        font-family: Ubuntu-Bold;
        font-size: 48px;
      }
    }
  }
}
</style>
