<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-starter-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div class="headline">
        <img
          :src="
            require(`@/assets/img/shop/offers/headline-starterpaket-${$store.state.locale}.png`)
          "
          class="w-100"
        />
      </div>
    </template>

    <template slot="default">
      <div v-if="rewards[0]" class="offer-reward-container to-center-abs">
        <div
          class="w-100 d-inline-flex align-items-center justify-content-end"
          :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 56px">
            {{ formatNumber(rewards[0].gold) }} x
          </div>
          <BaseAsset
            asset="primary"
            class="ms-2 yellow-text"
            size="big"
            style="width: 66px; height: 66px"
          />
        </div>

        <div
          class="w-100 d-inline-flex align-items-center justify-content-end"
          :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
        >
          <div class="bold yellow-text" style="font-size: 56px">
            {{ formatNumber(rewards[0].crown) }} x
          </div>
          <img
            :src="$whitelabel.currencies.secondary.large"
            style="width: 66px"
            class="ms-2 yellow-text"
          />
        </div>

        <div
          class="w-50 mx-auto d-inline-flex align-items-center justify-content-around"
        >
          <div v-if="rewards[0].card > 0">
            <BaseAsset asset="cards" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].card) }} x
            </div>
          </div>

          <div v-if="rewards[0].loyalty > 0">
            <BaseAsset asset="loyalty" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>
          </div>

          <div v-if="rewards[0].elixir > 0">
            <BaseAsset asset="tertiary" size="big" />
            <div class="primary-accent">
              {{ formatNumber(rewards[0].elixir) }} x
            </div>
          </div>

          <div v-if="rewards[0].expbooster > 0">
            <BaseAsset asset="booster" size="big" />
            <div class="primary-accent">
              {{ Math.round(rewards[0].expbooster / 60) }} Min.
            </div>
          </div>
        </div>
      </div>

      <div
        class="submit-button to-center-abs"
        @click="buyItem(0, 'Offer', 'Starter')"
      >
        <div>
          {{ $t("shop.offers.buy_button", { 0: "" }) }}
          <br />
          <span class="big position-relative"
            >{{ formatPrice(price[0]) }} €

            <div v-if="oldPrices && oldPrices[0]" class="offer-old-price">
              {{ formatPrice(oldPrices[0]) }} €
              <div class="offer-red-line to-center-abs"></div>
            </div>
            <div
              v-if="moreContent && moreContent[0]"
              class="big-badge-more-percent smaller"
            >
              <span class="big">{{ moreContent[0] }}</span>
              {{ $t("shop.offers.more_contents2") }}
            </div>
          </span>
        </div>
      </div>

      <div class="badge-big">
        <div>
          {{ $t("shop.offers.badge1", { 0: "" }) }} <span class="big">15</span>
        </div>
      </div>
      <div class="badge-small">
        <div>
          <span class="big">1 X</span>
          {{ $t("shop.offers.badge2", { 0: "" }) }}
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferStarter1",
  components: {
    BaseAsset,
  },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-starter-1");
  },
};
</script>
<style lang="scss">
$modal-width: 1000px;
$modal-height: 700px;

#modal-offer-starter-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/meinekrone-offer-overlay-starterpaket.png)
      no-repeat;
    background-size: 100%;
    background-position: bottom center;
  }

  .close-button {
    top: 50px;
    right: 50px;
  }

  .headline {
    position: absolute;
    top: -13px;
    left: 145px;
  }

  .offer-reward-container {
    top: 250px;
    right: 220px;
    text-align: right;
    line-height: 1.3;
  }

  .submit-button {
    width: 405px;
    height: 182px;
    background: url(~@/assets/img/shop/offers/btn-offer-green.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    text-align: center;
    padding-left: 85px;
    padding-right: 70px;
    display: inline-flex;
    line-height: 1;
    bottom: 10px;
    left: 220px;

    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }

    .offer-old-price {
      top: -55px;
      right: -130px;
    }
    .big-badge-more-percent {
      top: -450px;
      right: -220px;
    }
  }

  .badge-big {
    width: 181px;
    height: 180px;
    background: url(~@/assets/img/shop/offers/badge-big-back.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 21px;
    color: $primary-accent;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px $btn-drop-shadow);
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 10px;
    line-height: 1.1;
    position: absolute;
    right: -30px;
    bottom: 130px;

    .big {
      color: $primary-accent;
      font-family: Ubuntu-Bold;
      font-size: 48px;
    }
  }

  .badge-small {
    width: 143px;
    height: 143px;
    background: url(~@/assets/img/shop/offers/badge-big-back.png) no-repeat;
    background-size: cover;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: $primary-accent;
    text-transform: uppercase;
    font-family: Ubuntu-Bold;
    text-align: center;
    display: inline-flex;
    filter: drop-shadow(0 2px 4px $btn-drop-shadow);
    padding-left: 35px;
    padding-right: 35px;
    line-height: 1.3;
    position: absolute;
    right: 30px;
    bottom: 35px;
    padding-bottom: 10px;

    .big {
      color: $primary-accent;
      font-family: Ubuntu-Bold;
      font-size: 38px;
    }
  }
}
</style>
