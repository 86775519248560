<template>
  <b-modal
    v-if="lobbyOffer"
    id="modal-offer-winterhere-1"
    class="custom-modal1"
    :hide-footer="true"
    modal-class="custom-modal1"
    @hidden="onHidden"
  >
    <template slot="modal-header" slot-scope="{ close }">
      <a href="#" class="close-button" @click="close()"> </a>
      <div
        class="headline bg-img"
        :style="{
          backgroundImage:
            'url(' +
            $whitelabel.assets.offers.winterhere1[$store.state.locale] +
            ')',
        }"
        :class="$store.state.locale"
      ></div>
    </template>

    <template slot="default">
      <div
        v-if="rewards[0]"
        class="offer-reward-container position-absolute container"
      >
        <div class="h-100">
          <div
            class="w-100 d-flex flex-column align-items-center p-0"
            :style="{ visibility: rewards[0].gold > 0 ? 'visible' : 'hidden' }"
          >
            <img
              :src="$whitelabel.currencies.primary.triple"
              style="width: 185px; height: 116px"
            />
            <div class="yellow-text bold text-center" style="font-size: 56px">
              {{ formatNumber(rewards[0].gold) }}
            </div>
          </div>
          <div
            class="w-100 d-inline-flex align-items-center justify-content-center p-0"
            :style="{ visibility: rewards[0].crown > 0 ? 'visible' : 'hidden' }"
          >
            <span
              class="amount-crowns yellow-text bold text-center"
              style="font-size: 48px"
            >
              {{ formatNumber(rewards[0].crown) }} x
            </span>
            <BaseAsset asset="secondary" size="big" class="ms-2"></BaseAsset>
          </div>

          <div v-if="rewards[0].card > 0" class="package-bonus-icons">
            <div class="yellow-text bold">
              {{ formatNumber(rewards[0].card) }} x
            </div>

            <BaseAsset asset="cards" size="big" class="ms-2"></BaseAsset>
          </div>

          <div v-if="rewards[0].loyalty > 0" class="package-bonus-icons">
            <div class="yellow-text bold">
              {{ formatNumber(rewards[0].loyalty) }} x
            </div>

            <BaseAsset asset="loyalty" size="big" class="ms-2" />
          </div>
        </div>
      </div>

      <div
        class="submit-button position-absolute bg-img px-3"
        @click="buyItem(0, 'Offer', 'Event', closeThisModal)"
      >
        <div
          class="d-inline-flex align-items-center justify-content-around w-100"
        >
          {{ $t("shop.offers.buy_today") }}
          <span class="big position-relative"
            >{{ formatPrice(price[0]) }} €

            <div
              v-if="oldPrices && oldPrices[0]"
              class="offer-old-price ms-4 right"
            >
              {{ formatPrice(oldPrices[0]) }} €
              <div class="offer-red-line to-center-abs"></div>
            </div>
            <div
              v-if="moreContent && moreContent[0]"
              class="big-badge-more-percent smaller"
            >
              <span class="big">{{ moreContent[0] }}</span>
              {{ $t("shop.offers.more_contents2") }}
            </div>
          </span>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import offerModalContent from "@/mixins/offerModalContent.js";
import BaseAsset from "@/components/base/base-asset.vue";

export default {
  name: "ModalOfferWinterhere1",
  components: { BaseAsset },
  mixins: [offerModalContent],
  data() {
    return { isPipelineOffer: true };
  },
  mounted: function () {
    this.$bvModal.show("modal-offer-winterhere-1");
  },

  methods: {
    closeThisModal() {
      this.$bvModal.hide("modal-offer-winterhere-1");
    },
  },
};
</script>
<style lang="scss">
$modal-width: 1121px;
$modal-height: 630px;

#modal-offer-winterhere-1 {
  .modal-dialog {
    top: 50px;
    max-width: $modal-width;
    height: $modal-height;

    @media (max-height: 750px) {
      transform: scale(0.8);
      top: -60px;
      margin-bottom: -20%;
    }
  }

  .modal-content {
    width: $modal-width;
    background: url(~@/assets/img/shop/offers/winterda-bg-main.png) center/100%
      100% no-repeat;
  }

  .close-button {
    top: 0;
    right: 0;
  }

  .headline {
    position: absolute;
    top: 0;
    left: 0;
    &.de {
      width: 564px;
      height: 443px;
    }
    &.en {
      width: 631px;
      height: 396px;
    }
  }

  .offer-reward-container {
    top: 85px;
    right: 176px;
    height: 257px;
    line-height: 1;
    width: 314px;
  }

  .package-bonus-icons {
    display: inline-flex;
    align-items: center;
    height: 56px;
    font-size: 16px;
    .yellow-text {
      font-size: 32px;
    }
  }

  .submit-button {
    width: 387.4px;
    height: 139.4px;
    left: 62px;
    bottom: 20px;
    font-size: 18px;
    padding-top: 30px;
    color: white;
    text-transform: uppercase;
    cursor: pointer;
    font-family: Ubuntu-Bold;
    line-height: 1.2;
    text-shadow: 1px 2px 4px $text-shadow-dark-green;
    background-image: url(~@/assets/img/shop/offers/dwn-buy-button.png);
    &:hover {
      background-image: url(~@/assets/img/shop/offers/dwn-buy-button-hover.png);
    }
    .big {
      color: white;
      font-family: Ubuntu-Bold;
      font-size: 42px;
      text-shadow: 1px 2px 4px $text-shadow-dark-green;
    }

    .offer-old-price {
      top: -45px;
      right: -85px;
    }
    .big-badge-more-percent {
      top: -400px;
      right: -600px;
    }
  }
}
</style>
